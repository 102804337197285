import React from "react";
import { graphql } from "gatsby";
import { RichText } from "prismic-reactjs";
import { withPreview } from "gatsby-source-prismic";

// Core
import Layout from "@landingpage/core/src/components/Layout";
import SEO from "@landingpage/core/src/components/SEO";
import CookieConsent from "@landingpage/core/src/components/CookieConsent";
import Topbar from "@landingpage/core/src/components/Topbar";
import Footer from "@landingpage/core/src/components/Footer";

import { HeaderFactory } from "../components/Header";
import Slices from "../components/Slices";

const Homepage = ({ data }) => {
  const page = data.prismicHomepage;
  if (!page) return null;
  return (
    <Layout>
      <SEO
        lang={page.lang}
        title={RichText.asText(page.data?.seo_title?.raw)}
        description={RichText.asText(page.data?.seo_description?.raw)}
        keywords={RichText.asText(page.data?.seo_keywords?.raw)}
      />
      <CookieConsent
        cookieConsent={page.data?.cookie_consent?.document?.data}
      />
      <Topbar
        topbar={page.data?.topbar?.document?.data}
        logo={{ width: 230, height: 40 }}
      />
      <HeaderFactory slice={page.data?.header?.document?.data} />
      <Slices slices={page.data?.body} />
      <Footer
        year={page.last_publication_date}
        footer={page.data?.footer?.document?.data}
        borderTop
        logo={{ width: 150, height: 50 }}
      />
    </Layout>
  );
};

export const query = graphql`
  query HomepageById($id: String!) {
    prismicHomepage(id: { eq: $id }) {
      id
      lang
      last_publication_date(formatString: "Y")

      data {
        title {
          raw
        }
        seo_title {
          raw
        }
        seo_description {
          raw
        }
        seo_keywords {
          raw
        }
        cookie_consent {
          document {
            ...CookieConsentFragment
          }
        }
        topbar {
          document {
            ...TopbarFragment
          }
        }
        header {
          document {
            ...HeaderFragment
          }
        }
        footer {
          document {
            ...FooterFragment
          }
        }
        body {
          ...ContentBlockFragment
          ...UspContentFragment
          ...MoviesFragment
          ...MovieSliderFragment
          ...PricingFragment
          ...FaqFragment
        }
      }
    }
  }
`;

export default withPreview(Homepage);
