import React, { useState, useEffect } from "react";
import { RichText } from "prismic-reactjs";
import { useMediaQuery, Grid, Typography, Link } from "@material-ui/core";

import Container from "@landingpage/core/src/components/Container";
import { ResponsiveImage } from "@landingpage/core/src/components/Image";

import UspList from "./parts/UspList";
import CtaList from "./parts/CtaList";

import tvImg from "./tv.png";

import StyledComponent from "./Header.style";

const Content = ({ header }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <Grid
      container
      className="textContainer"
      direction="row"
      justifyContent="space-between"
      alignItems="stretch"
    >
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <Grid container direction="column" spacing={2}>
          <Grid className="mobile-center" item>
            <Typography className="title" variant="h1">
              {RichText.asText(header.title.raw)}
            </Typography>
            <Typography
              className="description"
              variant="body1"
              component="div"
              gutterBottom
            >
              {RichText.render(header.description.raw)}
            </Typography>
          </Grid>
          <Grid item>
            <UspList items={header.unique_selling_points} />
          </Grid>
          <Grid className="desktopLogin" item>
            <CtaList items={header.call_to_actions} />
          </Grid>
        </Grid>
      </Grid>
      <Grid className="imagePosition" item xs={12} sm={12} md={6} lg={6} xl={6}>
        <div className="deviceImage">
          {header.video && header.video.url ? (
            !loading && (
              <>
                <img
                  style={{
                    position: "relative",
                    zIndex: 2,
                    width: "100%",
                  }}
                  alt="Tv screen"
                  src={tvImg}
                />
                <div
                  style={{
                    width: "99.5%",
                    height: "100%",
                    maxHeight: "80.5%",
                    position: "absolute",
                    top: "4%",
                    left: "0%",
                  }}
                >
                  <video
                    style={{ height: "100%", width: "100%" }}
                    autoPlay
                    playsInline
                    muted
                    loop
                  >
                    <source src={header.video.url} type="video/mp4" />
                  </video>
                </div>
              </>
            )
          ) : (
            <ResponsiveImage image={header.image} />
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const HeaderHomepageAB = ({ header }) => {
  if (!header) return null;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), {
    noSsr: true,
  });

  return (
    <StyledComponent>
      <Container marginTop={!isMobile}>
        {!isMobile && <Content header={header} />}

        {isMobile && (
          <Grid
            container
            className="textContainer"
            direction="row"
            justifyContent="space-between"
            alignItems="stretch"
          >
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
              <Grid container direction="column" spacing={4}>
                <Grid className="mobile-center" item>
                  <Typography className="titleMobile" variant="h1">
                    {RichText.asText(header.title.raw)}
                  </Typography>
                </Grid>
                <Grid item>
                  <UspList items={header.unique_selling_points} />
                </Grid>
                <Grid className="desktopLogin" item>
                  <CtaList items={header.call_to_actions} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </StyledComponent>
  );
};

HeaderHomepageAB.defaultProps = {
  header: null,
};

HeaderHomepageAB.propTypes = {};

export default HeaderHomepageAB;
